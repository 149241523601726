<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Additional Comments</h3>
    </div>
    <hr />
    <div class="card-body pt-0">
      <div v-for="country in countriesWithAdditionalComments" class="row comments-row" :key="country.options.country.country_id">
        <div class="col">
          <h4>{{ country.options.country.value }}</h4>
          <template v-for="wikiItem in wikiItemsWithAdditionalComments(country)">
            <div class="row" :key="wikiItem.id">
              <div class="col">
                <label>
                  {{ wikiItem.feature_name }}
                </label>
                <br />
                <small>{{ wikiItem.additional }}</small>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'WikiAdditionalComments',
  computed: {
    countriesWithAdditionalComments() {
      return this.$store.getters.localPolicies.filter(
        (policy) => Object.keys(policy.options)
          .some(shortcode => policy.options[shortcode].additional && policy.options[shortcode].additional)
      );
    },
  },
  methods: {
    wikiItemsWithAdditionalComments(country) {
      let keys = Object.keys(country.options)
        .filter(shortcode => country.options[shortcode].additional && country.options[shortcode].additional);
      return keys.map(key => country.options[key]);
    },
  },
}
</script>
<style scoped>
h3 {
  font-size: 1.3em;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1em;
}
.card .row .col label {
  font-size: 0.7em;
}
.card .row .col small {
  font-size: 0.7em;
}
div.row.comments-row {
  border-bottom: 1px solid #e9ecef;
}
div.row.comments-row:last-of-type {
  border-bottom: none;
}
</style>
