export const FormatterMixin = {
  methods: {
    formatCountries(aCountries) {
      if (!aCountries) {
        return [];
      }

      if (aCountries.length > 5) {
        return aCountries
          .slice(0, 5)
          .concat(`<em>... and ${aCountries.length - 5} more</em>`);
      }
      return aCountries.map(country => country.country.country_name);
    }
  }
};

export default FormatterMixin;
