<template>
  <transition name="fade" mode="out-in">
    <td :is="tag" v-if="visible" :data-group="dataGroup">
      <slot></slot>
    </td>
  </transition>
</template>

<script>
export default {
  name: "FadingCell",
  props: {
    tag: {
      type: String,
      default: "td"
    },
    visible: {
      type: Boolean,
      default: true
    },
    dataGroup: {
      type: String,
      default: ""
    }
  },
  methods: {}
};
</script>
