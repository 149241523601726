import ApiService from "@/services/api.service";

const DocumentsService = {
  async uploadDocument(file, inquiryId, inquiryToolDocumentTypeId, iNewVersionOfInquiryToolProgressDocumentMapId = null, aCountries = null) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('inquiryId', inquiryId);
    formData.append('inquiryToolDocumentTypeId', inquiryToolDocumentTypeId);
    formData.append('countries', aCountries);

    if (iNewVersionOfInquiryToolProgressDocumentMapId) {
      formData.append('iNewVersionOfInquiryToolProgressDocumentMapId', iNewVersionOfInquiryToolProgressDocumentMapId);
    }

    try {
      let { data } = await ApiService.post(
        "progress/addFileToInquiry",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while uploading document:", err);
      return false;
    }
  },
  async removeDocument(file, inquiryId, inquiryToolDocumentTypeId) {
    let formData = new FormData();
    formData.append('documentIdHash', file.document_id_hash);
    formData.append('inquiryId', inquiryId);
    formData.append('inquiryToolDocumentTypeId', inquiryToolDocumentTypeId);

    try {
      let { data } = await ApiService.post(
        "progress/removeFileFromInquiry",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while removing document:", err);
      return false;
    }
  },
  async updateDocumentLabel(files, label) {
    let filesToUpdate = files.map(file => file.inquiry_tool_progress_document_map_id);
    let formData = new FormData();

    formData.append("filesToUpdate", JSON.stringify(filesToUpdate));
    formData.append("label", label);

    try {
      let { data } = await ApiService.post(
        "progress/updateDocumentLabel",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while updating document label:", err);
      return false;
    }
  }
};

export default DocumentsService;
