<template>
  <footer class="footer">
    <div>
      <div class="copyright text-center text-lg-left text-muted">
        © {{year}} <a href="http://www.icede.co.uk" class="font-weight-bold ml-1" target="_blank">iCede Solutions Ltd.</a>
        &nbsp;&nbsp;&nbsp;
        <a v-if="false" href="#" @click="changeTheme">
              <span v-if="dark">
                Switch to Classic Theme&nbsp;<sunrise-icon size="1.5x"></sunrise-icon>
              </span>
          <span v-else>
                Switch to Dark Theme&nbsp;<sunset-icon size="1.5x"></sunset-icon>
              </span>
        </a>
        <!--          <a href="https://www.creative-tim.com" class="font-weight-bold ml-1" target="_blank">Creative Tim</a> &-->
        <!--          <a href="https://www.binarcode.com" class="font-weight-bold ml-1" target="_blank">Binar Code</a>-->
      </div>
    </div>
    <div>
      <nav class="nav">
        <!--            <a href="https://www.icede.co.uk" class="nav-link" target="_blank">-->
        <!--              <external-link-icon size="1.2x"></external-link-icon>&nbsp;iCede-->
        <!--            </a>-->

        <a v-if="isTMG" class="nav-link">
          Privacy Policy -
          <a href="https://www.tokiomarine-nichido.co.jp/en/privacy/" target="_blank">English</a> |
          <a href="https://www.tokiomarine-nichido.co.jp/company/about/policy/kojin.html" target="_blank">Japanese</a>
        </a>

<!--    TODO: IMPLEMENT SUPPORT ON CLIENT PORTAL/LINK TO MAIN SYSTEM SUPPORT?    -->
        <a href="https://www.icede.co.uk/about/" class="nav-link" target="_blank">
          <external-link-icon size="1.2x"></external-link-icon>&nbsp;Support
        </a>
        <!--            <a href="https://www.icede.co.uk/contact/" class="nav-link" target="_blank">-->
        <!--              <external-link-icon size="1.2x"></external-link-icon>&nbsp;Contact-->
        <!--            </a>-->
      </nav>
    </div>
  </footer>
</template>
<script>
import Cookies from "js-cookie";
import {ExternalLinkIcon, SunriseIcon, SunsetIcon} from "vue-feather-icons";
import FeaturesMixin from "../mixins/features.mixin";
// import coolStyles from '@/assets/css/skin.cool.lazy.css';
// import darkStyles from '@/assets/css/skin.dark.lazy.css';
// import darkStyles from 'css-loader!@/assets/css/skin.dark.lazy.css';
// import coolStyles from 'css-loader!@/assets/css/skin.cool.lazy.css';

export default {
  components: {
    ExternalLinkIcon,
    SunriseIcon,
    SunsetIcon
  },
  data() {
    return {
      year: new Date().getFullYear(),
      dark: false,
    };
  },
  methods: {
    darkMode() {
      Cookies.set('df-mode', 'dark');
      this.dark = true;
    },
    classicMode() {
      Cookies.remove('df-mode');
      this.dark = false;
    },
    changeTheme() {
      if (this.dark) {
        this.classicMode();
      } else {
        this.darkMode();
      }
      window.location.reload();
    }
  },
  computed: {
    isTMG() {
      return this.isClient([process.env.VUE_APP_ICEDE_CLIENT_TMG]);
    }
  },
  mounted() {
    const hasMode = Cookies.get('df-mode');
    this.dark = (hasMode === 'dark');
    // if (hasMode === 'dark') {
    //   console.log("Dark mode on mount");
    //   darkStyles.use();
    // } else {
    //   console.log("Classic mode on mount");
    //   coolStyles.use();
    // }
  },
  mixins: [FeaturesMixin]
};
</script>
<style>
footer.footer {
  border-top: 1px solid rgba(72, 94, 144, 0.16);
}
</style>
