var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-file-version-wrapper"},[_c('b-modal',{staticClass:"modal-file-version",attrs:{"id":_vm.customModalId ?? 'modal-file-version-list',"size":"xl"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h2',[_c('i',{staticClass:"fa fa-book-open"}),_vm._v(" File Versions")])]},proxy:true}])},[_c('hr'),_c('div',{staticClass:"p-3"},[_c('h3',[_vm._v("Current Version")])]),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Version")]),_c('th',[_vm._v("File")]),_c('th',[_vm._v("Date Added")]),_c('th',[_vm._v("Download")]),_c('th',[_vm._v("Delete")]),_c('th',[_vm._v(" ")])])]),_c('tbody',_vm._l(([
            this.fileList[this.selectedFileVersionsIndex]
              ?.slice()
              ?.reverse()[0]
          ]),function(fileVersion){return _c('tr',{key:fileVersion?.inquiry_tool_progress_document_map_id},[_c('td',[_vm._v(" "+_vm._s(fileVersion?.version)+" ")]),_c('td',[_vm._v(" "+_vm._s(fileVersion?.document.filename)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$options.filters.formatDateUK(fileVersion?.document?.date_created))+" ")]),_c('td',[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button","disabled":fileVersion?.loading,"title":"Download file"},on:{"click":function($event){return _vm.download(fileVersion?.document_id)}}},[_c('i',{staticClass:"fa fa-download"})])]),_c('td',[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button","disabled":fileVersion?.loading,"title":"Remove file"},on:{"click":function($event){return _vm.remove(fileVersion?.document_id)}}},[_c('i',{staticClass:"fa fa-window-close"})])]),_c('td',[_c('b-overlay',{attrs:{"show":fileVersion?.loading}})],1)])}),0)]),_c('div',{staticClass:"p-3"},[_c('h3',[_vm._v("Previous Versions")])]),(
        this.fileList[this.selectedFileVersionsIndex]
          ?.slice()
          ?.reverse()
          ?.slice(1).length > 0
      )?_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Version")]),_c('th',[_vm._v("File")]),_c('th',[_vm._v("Date Added")]),_c('th',[_vm._v("Download")]),_c('th',[_vm._v("Delete")]),_c('th',[_vm._v(" ")])])]),_c('tbody',_vm._l((this.fileList[this.selectedFileVersionsIndex]
            ?.slice()
            ?.reverse()
            ?.slice(1)),function(fileVersion){return _c('tr',{key:fileVersion?.inquiry_tool_progress_document_map_id},[_c('td',[_vm._v(" "+_vm._s(fileVersion?.version)+" ")]),_c('td',[_vm._v(" "+_vm._s(fileVersion?.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$options.filters.formatDateUK(fileVersion?.date_created))+" ")]),_c('td',[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button","disabled":fileVersion?.loading,"title":"Download file"},on:{"click":function($event){return _vm.download(fileVersion?.document_id)}}},[_c('i',{staticClass:"fa fa-download"})])]),_c('td',[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button","disabled":fileVersion?.loading,"title":"Remove file"},on:{"click":function($event){return _vm.remove(fileVersion?.document_id)}}},[_c('i',{staticClass:"fa fa-window-close"})])]),_c('td',[_c('b-overlay',{attrs:{"show":fileVersion?.loading}})],1)])}),0)]):_c('div',[_vm._v(" There are no previous versions of this file. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }