<template>
  <div>
    <b-table :items="documentList" :fields="fields">
      <template v-slot:cell(inquiry_tool_progress_document_type_id)="data">
        <span>
          <b-badge
            :variant="
              inquiryDocumentTypeBadgeMap[
                data.item.inquiry_tool_progress_document_type_id
              ]
            "
          >
            {{
              inquiryProgressDocumentTypes[
                data.item.inquiry_tool_progress_document_type_id
              ]
            }}
          </b-badge>
        </span>
      </template>
      <template v-slot:cell(document_label)="data">
        <a class="text-primary text-decoration-underline cursor-pointer" @click="download(data.item.document.document_id)">
          {{ data.item.document_label ?? data?.item.document?.document_caption }}
          <p v-if="data.item.document_label">
            <small>{{ data.item?.document?.document_caption }}</small>
          </p>
        </a>
      </template>
      <template v-slot:cell(countries)="data">
        <b-badge v-if="data?.item?.countries?.length === 0" variant="secondary">
          All
        </b-badge>
        <b-badge
          v-else
          variant="secondary"
          v-for="(country, index) in formatCountries(data.item.countries)"
          :key="index"
          class="mr-1"
        >
          {{ country }}
        </b-badge>
      </template>
      <template v-slot:cell(date_created)="data">
        <span>
          {{ formatDateUKMixin(data.item?.document?.date_created) }}
        </span>
      </template>
      <template v-slot:cell(versions)="data">
        <b-button
          @click="
            openFileVersionListModal(
              documents.findIndex(doc => doc[0] === data.item)
            )
          "
        >
          <i class="fa fa-list"></i> {{ documents[documents.findIndex(doc => doc[0] === data.item)]?.length }}
        </b-button>
      </template>
    </b-table>

    <uploader-file-version-modal
      :fileList="documents"
      :selectedFileVersionsIndex="selectedFileVersionsIndex"
      @download="download"
      @remove="remove"
    ></uploader-file-version-modal>
  </div>
</template>

<script>
import UploaderFileVersionModal from "@underwriters/src/components/UploaderFileVersionModal";
import ApiService from "@clients/src/services/api.service";
import DocumentsService from "@/services/documents.service";
import Vue from "vue";
import FormatterMixin from "@underwriters/src/mixins/formatter.mixin";

export default {
  name: "InquiryDocumentsTableView",
  props: {
    documents: {
      type: Array,
      required: true
    }
  },
  components: { UploaderFileVersionModal },
  mixins: [ FormatterMixin ],
  mounted() {
    this.documentList = this.documents.map(documentGroup => {
      return documentGroup.filter(document => document.flag_archived === 0)[
        documentGroup?.length - 1
      ];
    });
  },
  computed: {
    inquiryProgressDocumentTypes() {
      return this.$store.getters.getInquiryProgressDocumentTypes.reduce(
        (acc, type) => {
          acc[type.inquiry_tool_progress_document_type_id] =
            type.inquiry_tool_progress_document_type_caption;
          return acc;
        },
        {}
      );
    },
    inquiryDocumentTypeBadgeMap() {
      return {
        1: "success",
        2: "warning",
        3: "default",
        4: "secondary",
        5: "danger",
        6: "primary"
      };
    }
  },
  data() {
    return {
      documentList: this.documents,
      selectedFileVersionsIndex: null,
      fields: [
        {
          key: "inquiry_tool_progress_document_type_id",
          label: "Type",
          sortable: true
        },
        { key: "document_label", label: "Name", sortable: true },
        { key: "date_created", label: "Created", sortable: true },
        { key: "countries", label: "Countries", sortable: true },
        { key: "versions", label: "Versions", sortable: false }
      ]
    };
  },
  methods: {
    openFileVersionListModal(index) {
      this.selectedFileVersionsIndex = index;

      this.$bvModal.show("modal-file-version-list");
    },
    async download(fileId) {
      let file = null;

      this.documents.forEach(group => {
        file = group.find(file => file.document_id === fileId);

        if (file) {
          Vue.set(file, "loading", true);
        }
      });

      if (!file) {
        return;
      }

      let response = await ApiService.get(
        process.env.VUE_APP_INQUIRY_API_ENDPOINT +
          "/document/" +
          file.document.document_id_hash,
        { responseType: "blob" }
      );

      this.downloadFile(response.data, file.document.document_caption);

      Vue.set(file, "loading", false);
    },
    remove(fileId) {
      let file = null;

      this.documents.forEach(group => {
        file = group.find(file => file.document_id === fileId);

        if (file) {
          Vue.set(file, "loading", true);
        }
      });

      if (!file) {
        return;
      }

      Vue.set(file, "loading", true);
      DocumentsService.removeDocument(file, this.inquiry.id, this.uploaderId)
        .then(() => {
          this.$store.dispatch("loadInquiries").then(() => {
            Vue.set(file, "loading", false);

            this.updateFileList();
          });
        })
        .catch(error => {
          console.log(error);
          Vue.set(file, "loading", false);
        });
    }
  }
};
</script>
