<template>
  <div class="home">
    <div class="row mt-2" id="root" v-if="selectedInquiry">
      <div class="col-6">
        <h5 class="mt-2">Attach Files to Inquiry</h5>
      </div>
      <div class="col-6">
        <div class="float-right">
          <button
            class="btn btn-primary"
            @click="bDocumentListView = !bDocumentListView"
          >
            <i class="fa fa-list" v-if="bDocumentListView" aria-hidden="true"></i>
            <i class="fa fa-th" v-else aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <div class="col-12" v-if="bDocumentListView">
       <inquiry-documents-table-view :documents="selectedInquiry.documents"></inquiry-documents-table-view>
      </div>
      <div class="col-12" v-else>
        <div class="row">
          <div
            v-for="inquiryProgressDocumentType in inquiryProgressDocumentTypes"
            v-bind:key="
              inquiryProgressDocumentType.inquiry_tool_progress_document_type_id
            "
            :class="{
              'col-md-6': !inquiryProgressDocumentType.flag_large_uploader,
              'col-lg-12': inquiryProgressDocumentType.flag_large_uploader,
              'col-sm-12': true
            }"
          >
            <uploader
              :uploaderId="
                inquiryProgressDocumentType.inquiry_tool_progress_document_type_id.toString()
              "
              :inquiry="selectedInquiry"
              :title="
                inquiryProgressDocumentType.inquiry_tool_progress_document_type_caption
              "
            ></uploader>
          </div>
        </div>
      </div>
    </div>
    <inquiry-actions>
      <span class="float-right">
        <span v-if="$store.getters.submitting">
          <i class="fa fa-sync fa-spin"></i>&nbsp;Instructing Programme...
        </span>
        <tooltip-button
          v-else
          text="Instruct"
          icon="paper-plane"
          btn-class="btn-success"
          :disabled="!underwritingPortalMode() && $store.getters.submitted"
          disabled-tooltip-text="This programme has already been instructed"
          disabled-tooltip-level="success"
          disabled-tooltip-position="left"
          @click="submitProgramme"
        ></tooltip-button>
      </span>
    </inquiry-actions>
  </div>
</template>
<script>
import Uploader from "@/components/Uploader";
import InquiryActions from "@underwriters/src/components/InquiryActions";
import TooltipButton from "@underwriters/src/components/TooltipButton";
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
import InquiryDocumentsTableView from "@underwriters/src/components/InquiryDocumentsTableView";

export default {
  name: "AddFiles",
  components: {
    Uploader,
    InquiryActions,
    TooltipButton,
    InquiryDocumentsTableView
  },
  mixins: [FeaturesMixin],
  data: () => {
    return {
      bDocumentListView: false
    };
  },
  computed: {
    selectedInquiry() {
      return (
        this.$store.getters.getAllInquiries.filter(
          inquiry => parseInt(inquiry.id) === parseInt(this.inquiryId)
        )[0] ?? null
      );
    },
    inquiryProgressDocumentTypes() {
      return this.$store.getters.getInquiryProgressDocumentTypes;
    }
  },
  props: {
    inquiryId: {
      type: [Number, String],
      default: null
    }
  },
  methods: {
    async submit() {
      this.$store.commit("setProgrammeSubmitted");
      await this.$store.dispatch("storeState");
      await this.$router.push({ name: "Instructed Accounts" });
    },
    async submitProgramme() {
      if (!this.$store.getters.isLoggedIn) {
        this.action = "Submit";
        this.$bvModal.show("modal-login");
      } else {
        await this.submit();
      }
    }
  }
};
</script>
