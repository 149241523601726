<template>
    <div>
        <div class="card p-3 mt-2 ">
            <div class="d-flex justify-content-between align-items-center">
                <div class="user d-flex flex-row align-items-center">
                    <span v-if="this.comment.inquiry_user_id !== null">
                        <small class="font-weight-bold text-primary">{{ this.comment.posted_by_client_broker.full_name }}</small>
                    </span>
                    <span v-else>
                        <small class="font-weight-bold text-primary">{{ this.comment.posted_by.full_name }} ({{ this.comment.posted_by.company.regional_company_name }})</small>
                    </span>
                </div>
                <div>
                    <small>{{ this.comment.date_created }}</small>
                </div>
            </div>

            <div class="action d-flex justify-content-between mt-2 align-items-center">
                <span v-html="this.comment.text_comment"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Comment",
    props: ['comment'],
}
</script>