<template>
  <b-modal id="modal-add-cover" size="lg" hide-footer ref="modal-add-cover">
    <template v-slot:modal-header>
      <h3 class="my-0"><i class="fa fa-plus"></i>&nbsp;Add Cover</h3>
      <button class="pull-right btn btn-xs btn-secondary" @click="handleCancel">
        <i class="fa fa-times fa-2x"></i>
      </button>
    </template>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="form-group col">
            <label for="inpCompanyPeril">Peril Name</label>
            <input
              id="inpCompanyPeril"
              class="form-control"
              type="text"
              placeholder="Enter new peril name"
              :disabled="!editingCustomPeril"
              v-model="perilName"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label for="inpCompanyPerilDescription">Description</label>
            <textarea
              id="inpCompanyPerilDescription"
              class="form-control"
              type="text"
              :placeholder="
                editingCustomPeril
                  ? 'Enter new peril description (optional)'
                  : ''
              "
              :disabled="!editingCustomPeril"
              v-model="description"
              rows="8"
            ></textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div
              v-if="perilExistsOnPolicy(perilId)"
              v-b-tooltip.hover.top="{
                title: 'This peril already exists on the policy cover',
                variant: 'info'
              }"
              style="display: inline-block"
            >
              <button disabled class="btn btn-success btn-fill">
                <i class="fa fa-plus"></i>&nbsp;Add
              </button>
            </div>
            <button
              v-else
              class="btn btn-success btn-fill"
              @click="addPerilToCoverage"
            >
              <i class="fa fa-plus"></i>&nbsp;Add
            </button>
            &nbsp;
            <button class="btn btn-warning ml-1" @click="handleCancel">
              <i class="fa fa-window-close"></i>&nbsp;Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <h5 class="mb-1">
          Create a new peril, or select one from the list below.
        </h5>
        <button
          class="btn btn-primary btn-sm mb-2"
          @click="startCustomPeril"
          :disabled="perilId.toString().startsWith('custom')"
        >
          <i class="fa fa-pen"></i>&nbsp;New Peril
        </button>
        <div id="companyPerilContainer">
          <ul>
            <li
              v-for="peril in perils"
              @click="setSelectedPeril(peril.coverage_company_peril_id)"
              :class="{
                'selected-peril': perilId === peril.coverage_company_peril_id,
                'peril-added': perilExistsOnPolicy(
                  peril.coverage_company_peril_id
                )
              }"
            >
              {{ peril.peril_caption }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
#companyPerilContainer {
  max-height: 350px;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid #dee2e6;
}
#companyPerilContainer ul {
  padding: 0;
  margin: 0;
}
#companyPerilContainer ul li {
  list-style: none;
  cursor: pointer;
  padding: 8px 20px;
  border-bottom: 1px solid #f4f4f4;
}
#companyPerilContainer ul li.peril-added {
  background-color: #eefeee;
}
#companyPerilContainer ul li:hover {
  background-color: #f8f8f8;
}

#companyPerilContainer ul li.peril-added:hover {
  background-color: #eafaea;
}

textarea#inpCompanyPerilDescription {
  font-size: 0.9rem;
  resize: none;
}

ul li.selected-peril {
  background-color: #f2f2f2;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
export default {
  name: "AddCoverModal",
  methods: {
    handleCancel() {
      // Hide the modal...
      this.$refs["modal-add-cover"].hide();
    },
    setSelectedPeril(iPerilId) {
      if (!iPerilId || iPerilId.length === 0) {
        this.perilId = "";
        this.perilName = "";
        this.description = "";
        this.editingCustomPeril = true;
      } else {
        this.perilId = iPerilId;
        const peril = this.perils.find(
          peril => peril.coverage_company_peril_id === iPerilId
        );
        this.perilName = peril.peril_caption;
        this.description = peril.peril_definition;
        this.editingCustomPeril = iPerilId.toString().startsWith("custom");
      }
    },
    startCustomPeril() {
      if (
        this.perilExistsOnPolicy(
          "custom" + this.$store.getters.lastCustomPerilId
        )
      ) {
        this.$store.commit("incrementLastCustomPerilId");
      }
      this.perilId = "custom" + this.$store.getters.lastCustomPerilId;
      this.perilName = "";
      this.description = "";
      this.editingCustomPeril = true;
    },
    perilExistsOnPolicy(perilId) {
      const covers = this.$store.getters.selectedTemplate.covers;
      return (
        covers.find(cover => cover.id.toString() === perilId.toString()) !==
        undefined
      );
    },
    addPerilToCoverage() {
      this.$store.dispatch("addCover", {
        id: this.perilId,
        name: this.perilName
      });
      this.startCustomPeril();
      this.handleCancel();
    }
  },
  data: () => ({
    perilName: "",
    perilId: "custom1",
    editingCustomPeril: true,
    description: "",
    occurrence: 0,
    aggregate: 0,
    standardDeductible: 0,
    minDeductible: 0,
    pcDeductible: 0
  }),
  computed: {
    perils() {
      return this.$store.getters.companyPerils;
    }
  },
  mounted() {
    this.startCustomPeril();
  }
};
</script>
