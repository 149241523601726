<template>
  <div class="cover-select">
    <div class="row mt-2">
      <div class="col">
        <h5 class="mt-2">Cover Review - Local Coverage Specification</h5>

        <cover-details> </cover-details>

        <div class="row">
          <div
            class="col"
            style=""
            id="coverageReviewTableContainer"
            :class="{ 'restrict-width': noSidebarMode }"
          >
            <b-table-simple sticky-header small hover>
              <b-thead>
                <b-th sticky-column class="border-right">Country</b-th>
                <b-th
                  v-for="cover in selectedCovers"
                  :key="cover.id"
                  class="text-nowrap"
                >
                  {{ cover.data.name }}
                </b-th>
              </b-thead>
              <b-tbody>
                <b-tr class="commonRow">
                  <b-td sticky-column class="border-right text-center">
                    <span class="text-muted">Common</span>
                    <br />
                    <button
                      v-show="showCommonExtra"
                      class="btn btn-sm btn-primary expander"
                      @click="showCommonExtra = !showCommonExtra"
                    >
                      <i class="fa fa-caret-up"></i>
                    </button>
                    <button
                      v-show="!showCommonExtra"
                      class="btn btn-sm btn-primary expander"
                      @click="showCommonExtra = !showCommonExtra"
                    >
                      <i class="fa fa-caret-down"></i>
                    </button>
                  </b-td>
                  <b-td
                    v-for="cover in this.commonData"
                    class="coverageCell"
                    :key="cover.id"
                  >
                    <select
                      class="form-control form-control-sm bg-primary text-white"
                      style="display: inline;"
                      v-model="cover.included"
                    >
                      <option value="1">Included</option>
                      <option value="0">Excluded</option>
                      <option value="2">Silent</option>
                    </select>
                    &nbsp;
                    <button
                      style="margin-top: -2px; font-size:0.84rem;"
                      class="btn btn-outline-secondary btn-sm"
                      @click="
                        $store.dispatch('updatePolicyValue', {
                          countryId: 'all',
                          coverId: cover.id,
                          type: 'inc',
                          value: cover.included
                        })
                      "
                    >
                      <i class="fa fa-arrow-down"></i>&nbsp;Apply
                    </button>
                  </b-td>
                </b-tr>
                <transition name="fade">
                  <b-tr
                    v-if="showCommonExtra"
                    class="rowExtraDetails commonRow"
                  >
                    <b-td sticky-column class="border-right text-muted">
                      Common Cover Details
                    </b-td>
                    <b-td
                      v-for="cover in this.commonData"
                      :key="cover.id"
                      class="border-right commonExtra"
                    >
                      <div class="row">
                        <div class="col">
                          <strong>Limit</strong>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <b-input-group size="sm" prepend="Occ.">
                            <currency-formatted-input
                              :value="cover.occurrenceLimit"
                              inputClass="form-control-sm"
                              @input="value => (cover.occurrenceLimit = value)"
                            >
                            </currency-formatted-input>
                            <b-input-group-append>
                              <b-button
                                variant="outline-secondary"
                                v-b-tooltip.hover
                                title="Apply this Occurrence Limit to all countries"
                                @click="
                                  $store.dispatch('updatePolicyValue', {
                                    countryId: 'all',
                                    coverId: cover.id,
                                    type: 'cover-limit-occurrenceLimit',
                                    value: cover.occurrenceLimit
                                  })
                                "
                              >
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                        <div class="col">
                          <b-input-group size="sm" prepend="Agg.">
                            <currency-formatted-input
                              :value="cover.aggregateLimit"
                              inputClass="form-control-sm"
                              @input="value => (cover.aggregateLimit = value)"
                            >
                            </currency-formatted-input>
                            <b-input-group-append>
                              <b-button
                                variant="outline-secondary"
                                v-b-tooltip.hover
                                title="Apply this Aggregate Limit to all countries"
                                @click="
                                  $store.dispatch('updatePolicyValue', {
                                    countryId: 'all',
                                    coverId: cover.id,
                                    type: 'cover-limit-aggregateLimit',
                                    value: cover.aggregateLimit
                                  })
                                "
                              >
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                      <hr />
                      <div class="row mb-2">
                        <div class="col">
                          <strong>Deductible</strong>
                        </div>
                        <div class="col percentDed">
                          <b-input-group size="sm">
                            <b-input-group-prepend>
                              <b-button
                                variant="outline-secondary"
                                v-b-tooltip.hover
                                title="Apply this Percentage Deductible to all countries"
                                @click="
                                  $store.dispatch('updatePolicyValue', {
                                    countryId: 'all',
                                    coverId: cover.id,
                                    type: 'cover-limit-percentageDeductible',
                                    value: cover.percentageDeductible
                                  })
                                "
                              >
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                            </b-input-group-prepend>
                            <currency-formatted-input
                              :value="cover.percentageDeductible"
                              inputClass="form-control-sm"
                              @input="
                                value => (cover.percentageDeductible = value)
                              "
                            >
                            </currency-formatted-input>
                            <template v-slot:append>
                              <b-input-group-text>%</b-input-group-text>
                            </template>
                          </b-input-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <b-input-group size="sm" prepend="Min.">
                            <currency-formatted-input
                              :value="cover.minDeductible"
                              inputClass="form-control-sm"
                              @input="value => (cover.minDeductible = value)"
                            >
                            </currency-formatted-input>
                            <b-input-group-append>
                              <b-button
                                variant="outline-secondary"
                                v-b-tooltip.hover
                                title="Apply this Minimum Deductible to all countries"
                                @click="
                                  $store.dispatch('updatePolicyValue', {
                                    countryId: 'all',
                                    coverId: cover.id,
                                    type: 'cover-limit-minDeductible',
                                    value: cover.minDeductible
                                  })
                                "
                              >
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                        <div class="col">
                          <b-input-group size="sm" prepend="Max.">
                            <currency-formatted-input
                              :value="cover.maxDeductible"
                              inputClass="form-control-sm"
                              @input="value => (cover.maxDeductible = value)"
                            >
                            </currency-formatted-input>
                            <b-input-group-append>
                              <b-button
                                variant="outline-secondary"
                                v-b-tooltip.hover
                                title="Apply this Maximum Deductible to all countries"
                                @click="
                                  $store.dispatch('updatePolicyValue', {
                                    countryId: 'all',
                                    coverId: cover.id,
                                    type: 'cover-limit-maxDeductible',
                                    value: cover.maxDeductible
                                  })
                                "
                              >
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                </transition>
                <b-tr class="commonSeparator"></b-tr>
                <template v-for="country in $store.getters.localPolicies">
                  <b-tr :key="country.options.country.id + 'A'">
                    <b-td sticky-column class="border-right text-center">
                      {{ country.options.country.value }}
                      <br />
                      <button
                        v-show="country.showExtra"
                        class="btn btn-sm btn-primary expander"
                        @click="
                          $store.dispatch('updatePolicyValue', {
                            countryId: country.options.country.id,
                            coverId: '',
                            type: 'extra',
                            value: false
                          })
                        "
                      >
                        <i class="fa fa-caret-up"></i>
                      </button>
                      <button
                        v-show="!country.showExtra"
                        class="btn btn-sm btn-primary expander"
                        @click="
                          $store.dispatch('updatePolicyValue', {
                            countryId: country.options.country.id,
                            coverId: '',
                            type: 'extra',
                            value: true
                          })
                        "
                      >
                        <i class="fa fa-caret-down"></i>
                      </button>
                    </b-td>
                    <b-td
                      v-for="cover in selectedCovers"
                      class="coverageCell"
                      :key="cover.id"
                    >
                      <div
                        v-if="country.options.goodLocalStandard.userValue"
                        v-b-tooltip="{
                          title:
                            'Coverage will be included based on the local office\'s standard',
                          trigger: 'hover',
                          variant: 'info'
                        }"
                        style="display: inline-block;"
                      >
                        <select
                          class="form-control form-control-sm sel-gls"
                          :disabled="
                            country.options.goodLocalStandard.userValue
                          "
                        >
                          <!-- TODO: Do we need to get and show the value for GLS for each cover here? -->
                          <option selected="selected"
                            >Good Local Standard</option
                          >
                        </select>
                      </div>
                      <select
                        v-else
                        class="form-control form-control-sm"
                        :disabled="country.options.goodLocalStandard.userValue"
                        :class="getSelectClass(country.covers[cover.id])"
                        @input="
                          $store.dispatch('updatePolicyValue', {
                            countryId: country.options.country.id,
                            coverId: cover.id,
                            type: 'inc',
                            value: $event.target.value
                          })
                        "
                      >
                        <option
                          :selected="country.covers[cover.id].included === 1"
                          value="1"
                          >Included</option
                        >
                        <option
                          :selected="country.covers[cover.id].included === 0"
                          value="0"
                          >Excluded</option
                        >
                        <option
                          :selected="country.covers[cover.id].included === 2"
                          value="2"
                          >Silent</option
                        >
                      </select>
                    </b-td>
                  </b-tr>
                  <transition
                    name="fade"
                    :key="country.options.country.id + 'transition'"
                  >
                    <b-tr
                      v-if="country.showExtra"
                      class="rowExtraDetails"
                      :key="country.options.country.id + 'B'"
                    >
                      <b-td sticky-column class="border-right text-muted">
                        {{ country.options.country.value + " Cover Details" }}
                      </b-td>
                      <b-td
                        v-for="cover in country.covers"
                        :key="cover.id"
                        class="border-right"
                      >
                        <div class="row">
                          <div class="col">
                            <strong>Limit</strong>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-input-group size="sm" prepend="Occ.">
                              <input
                                v-if="
                                  country.options.goodLocalStandard.userValue
                                "
                                type="text"
                                class="form-control-sm"
                                style="width: 120px; font-size: 0.65rem;height:initial;"
                                disabled="disabled"
                              />
                              <currency-formatted-input
                                v-else
                                :value="cover.occurrenceLimit"
                                inputClass="form-control-sm"
                                @input="
                                  value =>
                                    $emit(
                                      'country-cover-update',
                                      country.options.country.id,
                                      cover.id,
                                      'cover-limit-occurrenceLimit',
                                      value
                                    )
                                "
                              >
                              </currency-formatted-input>
                            </b-input-group>
                          </div>
                          <div class="col">
                            <b-input-group size="sm" prepend="Agg.">
                              <input
                                v-if="
                                  country.options.goodLocalStandard.userValue
                                "
                                type="text"
                                class="form-control-sm"
                                style="width: 120px; font-size: 0.65rem;height:initial;"
                                disabled="disabled"
                              />
                              <currency-formatted-input
                                v-else
                                :value="cover.aggregateLimit"
                                inputClass="form-control-sm"
                                @input="
                                  value =>
                                    $emit(
                                      'country-cover-update',
                                      country.options.country.id,
                                      cover.id,
                                      'cover-limit-aggregateLimit',
                                      value
                                    )
                                "
                              >
                              </currency-formatted-input>
                            </b-input-group>
                          </div>
                        </div>
                        <hr />
                        <div class="row mb-2">
                          <div class="col">
                            <strong>Deductible</strong>
                          </div>
                          <div class="col percentDed">
                            <b-input-group size="sm" append="%">
                              <input
                                v-if="
                                  country.options.goodLocalStandard.userValue
                                "
                                type="text"
                                class="form-control-sm"
                                style="width: 48px; font-size: 0.65rem;height:initial;"
                                disabled="disabled"
                              />
                              <currency-formatted-input
                                v-else
                                :value="cover.percentageDeductible"
                                inputClass="form-control-sm"
                                @input="
                                  value =>
                                    $emit(
                                      'country-cover-update',
                                      country.options.country.id,
                                      cover.id,
                                      'cover-limit-percentageDeductible',
                                      value
                                    )
                                "
                              >
                              </currency-formatted-input>
                            </b-input-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-input-group size="sm" prepend="Min.">
                              <input
                                v-if="
                                  country.options.goodLocalStandard.userValue
                                "
                                type="text"
                                class="form-control-sm"
                                style="width: 120px; font-size: 0.65rem;height:initial;"
                                disabled="disabled"
                              />
                              <currency-formatted-input
                                v-else
                                :value="cover.minDeductible"
                                inputClass="form-control-sm"
                                @input="
                                  value =>
                                    $emit(
                                      'country-cover-update',
                                      country.options.country.id,
                                      cover.id,
                                      'cover-limit-minDeductible',
                                      value
                                    )
                                "
                              >
                              </currency-formatted-input>
                            </b-input-group>
                          </div>
                          <div class="col">
                            <b-input-group size="sm" prepend="Max.">
                              <input
                                v-if="
                                  country.options.goodLocalStandard.userValue
                                "
                                type="text"
                                class="form-control-sm"
                                style="width: 120px; font-size: 0.65rem;height:initial;"
                                disabled="disabled"
                              />
                              <currency-formatted-input
                                v-else
                                :value="cover.maxDeductible"
                                inputClass="form-control-sm"
                                @input="
                                  value =>
                                    $emit(
                                      'country-cover-update',
                                      country.options.country.id,
                                      cover.id,
                                      'cover-limit-maxDeductible',
                                      value
                                    )
                                "
                              >
                              </currency-formatted-input>
                            </b-input-group>
                          </div>
                        </div>
                      </b-td>
                    </b-tr>
                  </transition>
                </template>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <inquiry-actions>
      <span class="float-right">
        <span v-if="usingAddFileToInquiry()">
          <button
              class="btn btn-success"
              :disabled="saving"
              @click="goToCommunication();"
          >
            <i class="fa fa-file-invoice"></i>&nbsp; Communication
          </button>
        </span>
        <span v-else>
          <span v-if="$store.getters.submitting">
            <i class="fa fa-sync fa-spin"></i>&nbsp;Submitting Programme...
          </span>
          <tooltip-button
            v-else
            text="Submit"
            icon="paper-plane"
            btn-class="btn-success"
            :disabled="!underwritingPortalMode() && $store.getters.submitted"
            disabled-tooltip-text="This programme has already been instructed"
            disabled-tooltip-level="success"
            disabled-tooltip-position="left"
            @click="submitProgramme"
          ></tooltip-button>
        </span>
      </span>
    </inquiry-actions>
    <login-modal
      v-if="!$store.getters.isLoggedIn"
      :email="$store.getters.emailAddress"
      :subtitle="'Please login before you can ' + action"
      :login-button-text="'Login and ' + action"
      @logged-in="loggedIn"
    ></login-modal>
  </div>
</template>

<style scoped lang="less">
#coverageReviewTableContainer th {
  background-color: white;
  z-index: 1000;
  position: sticky;
  top: -1px;
  font-size: 1rem;
  color: #555;
}
#coverageReviewTableContainer .b-table-sticky-column {
  font-size: 1rem;
  color: #555;
}
#coverageReviewTableContainer th:first-of-type {
  z-index: 1001;
  position: sticky;
  top: -1px;
  left: 0;
}

#coverageReviewTableContainer td.coverageCell {
  min-width: 380px;
}

#coverageReviewTableContainer select.form-control {
  max-width: 150px;
  height: 35px;
}

#coverageReviewTableContainer button.btn.expander {
  width: 25px;
  height: 25px;
  padding: 1px 6px;
}

@tableHeight: 500px;

#coverageReviewTableContainer {
  min-height: initial;
  overflow: auto;
  padding-right: 0;
  padding-bottom: 0;
  margin-right: 15px;
}

#coverageReviewTableContainer.restrict-width {
  max-width: 1000px;
}

#coverageReviewTableContainer .b-table-sticky-header {
  min-height: initial;
  max-height: @tableHeight;
}

#coverageReviewTableContainer:before,
#coverageReviewTableContainer:after {
  z-index: 999999;
  content: " ";
  position: absolute;
}

tr td .input-group input.form-control {
  width: 110px;
}

/* Common extra row buttons need to be smaller s.t. the appended buttons will fit on
     the same row */
tr.rowExtraDetails td.commonExtra input.form-control {
  max-width: 88px;
}

/* Stop common apply all buttons from clipping above the sticky country TDs */
tr.rowExtraDetails td.commonExtra .input-group .btn {
  z-index: 1;
}

.rowExtraDetails .col.percentDed .input-group input.form-control {
  max-width: 48px;
}

.rowExtraDetails hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top-color: rgba(0, 0, 0, 0.07);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#coverageReviewTableContainer select.sel-gls {
  font-size: 0.68rem;
  background-color: #fafafa;
  color: #444;
}
</style>

<script>
import CurrencyFormattedInput from "./CurrencyFormattedInput";
import TooltipButton from "./TooltipButton";
import LoginModal from "./LoginModal";
import InquiryActions from "./InquiryActions";
import CoverDetails from "./CoverDetails";
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
export default {
  name: "CoverSelect",
  components: {
    TooltipButton,
    CurrencyFormattedInput,
    LoginModal,
    InquiryActions,
    CoverDetails
  },
  props: {},
  mixins: [FeaturesMixin],
  data: () => {
    return {
      showCommonExtra: false,
      commonData: [],
      submitting: false,
      saving: false,
      action: "Save"
    };
  },
  computed: {
    selectedCovers() {
      const template = this.$store.getters.selectedTemplate;
      if (!template || this.$store.getters.localPolicies.length === 0) {
        return [];
      }
      let firstPolicy = this.$store.getters.localPolicies[0];
      let selected = template.covers.filter(
        cover => firstPolicy.covers[cover.id] !== undefined
      );
      return selected;
    },
    noSidebarMode() {
      return process.env.VUE_APP_SIDEBAR_ACTIVE === "0";
    }
  },
  methods: {
    changeTemplate(value) {
      this.$emit("change-template", value);
    },
    generateCommonData() {
      if (!this.selectedCovers || this.selectedCovers.length === 0) {
        return;
      }
      this.commonData = [];
      let newData = [];
      this.selectedCovers.forEach(cover => {
        newData.push({
          id: cover.id,
          included: 1,
          occurrenceLimit: "",
          aggregateLimit: "",
          percentageDeductible: "",
          minDeductible: "",
          maxDeductible: ""
        });
      });
      this.commonData = newData;
    },
    uncheckAllCountries() {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: false
      });
      this.$store.commit("resetProgramme");
      this.$router.replace({ name: "Country Select" });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Inquiries" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.action = "Save";
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    },
    async submit() {
      this.$store.commit("setProgrammeSubmitted");
      await this.$store.dispatch("storeState");
      await this.$router.push({ name: "Inquiries" });
    },
    async submitProgramme() {
      if (!this.$store.getters.isLoggedIn) {
        this.action = "Submit";
        this.$bvModal.show("modal-login");
      } else {
        await this.submit();
      }
    },
    loggedIn() {
      if (this.action === "Save") {
        this.save();
      } else {
        this.submit();
      }
    },
    getSelectClass(cover) {
      if (!cover) {
        return "bg-secondary";
      }
      // This returns success where you would expect the select to show included... 🤔
      let bgClass = "bg-success";
      if (cover.included === 2) {
        bgClass = "bg-warning";
      } else if (
        cover.included !== 2 &&
        ((cover.included === 1 && !cover.allowInclude) ||
          (cover.included === 0 && !cover.allowExclude))
      ) {
        bgClass = "bg-danger";
      }

      if (bgClass !== "bg-warning") {
        bgClass += " text-white";
      }
      return bgClass;
    },
    async goToCommunication() {
      await this.$router.push({
        name: "Communication",
      });
    }
  },
  mounted() {
    // Redirect to Programme Instructions tab if we don't have the data for this tab
    if (!this.$store.getters.programmeInstructionsActive) {
      this.$router.replace({ name: "Programme Instructions" });
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    this.generateCommonData();

    document.getElementById("myTab").scrollIntoView({ behavior: "smooth" });
  }
};
</script>
