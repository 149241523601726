<!DOCTYPE html>
<template>
  <div class="new-auth-layout">
    <header class="navbar navbar-header navbar-header-fixed">
      <a href="#" id="mainMenuOpen" class="burger-menu" @click="openMainMenu"
      ><i class="fa fa-bars"></i
      ></a>
      <div class="navbar-brand">
        <a href="#">
          <img
              src="@clients/src/assets/img/brand/main_transp-icede.png"
              alt="iCede Logo"
              width="128"
              height="48"
          />


        </a>
      </div>
      <!-- navbar-brand -->
      <div id="navbarMenu" class="navbar-menu-wrapper">
        <div class="navbar-menu-header">
          <a href="#"
          ><img
              src="@clients/src/assets/img/brand/iCedeLogo.png"
              alt="iCede Logo"
              width="45"
              height="45"
          /></a>
          <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
        </div>
      </div>
      <!-- navbar-menu-wrapper -->
      <div class="navbar-right"></div>
      <!-- navbar-right -->
    </header>
    <!-- navbar -->
    <div class="container-fluid">
      <div class="row row-xs content-row content-padding">
        <!--  https://pixabay.com/photos/mountains-dolomites-alps-6540497/    -->

        <div

            class="d-none d-md-block col-md-4 col-lg-6 bg-primary-light"
        >
          <div class="row row-xs justify-content-center" style="height: 100%">
            <div class="col-12 text-center">
              <span class="helper"></span>
              <scaled-image
                  :src="
                  require(`@clients/src/assets/img/icede-client/${iCedeClient}/logos/main_transp.png`)
                "
                  :alt="iCedeClient"
                  :width="450"
                  class="client-logo-centered"
              />
            </div>
            <div class="col-12 text-center row-2">
              <span class="helper"></span>
              <scaled-image
                  v-for="img in getClientExtraLogos()"
                  :key="img"
                  :src="img"
                  :alt="iCedeClient"
                  :width="150"
                  class="client-logo-centered"
              />
              <div v-if="getClientExtraLogos().length === 0" style="height: 150px;"></div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 px-4 px-md-0 login-wrapper">
          <div class="row row-xs justify-content-center login-row">
            <div class="col-sm-12">
              <h1 class="text-center">{{ underwritingPortalMode() ? 'Underwriting Portal' : 'Client Portal' }}</h1>
            </div>
            <div class="col-md-8 col-lg-6">
              <slide-y-up-transition mode="out-in" origin="center top">
                <router-view @afterlogin="doAfterLogin"></router-view>
              </slide-y-up-transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <content-footer />
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";
import ContentFooter from "@clients/src/layout/ContentFooter";

import "@clients/src/assets/js/dashforge.js";
// Vendor styles
import "@clients/src/assets/js/@fortawesome/fontawesome-free/css/all.min.css";
import "@clients/src/assets/js/ionicons/css/ionicons.min.css";

// Dashforge styles
import "@clients/src/assets/css/dashforge.css";
import "@clients/src/assets/css/dashforge.auth.css";
import "@clients/src/assets/css/skin.cool.lazy.css";
import { LogInIcon } from "vue-feather-icons";
import ScaledImage from "@/components/ScaledImage";

import FeaturesMixin from "@/mixins/features.mixin";
import ClientsFeaturesMixin from "@clients/src/mixins/features.mixin";

import { SET_SINGLE_CLIENT } from "@/store/mutation-types";

export default {
  name: "NewAuthLayout",
  components: { ScaledImage, ContentFooter, SlideYUpTransition, LogInIcon },
  computed: {
    iCedeClient() {
      return process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();
    },
  },
  data: () => ({
    useBackgroundImage: false,
  }),
  mixins: [FeaturesMixin, ClientsFeaturesMixin],
  methods: {
    openMainMenu() {
      document.getElementsByTagName("body")[0].classList.add("navbar-nav-show");
    },
    closeMainMenu() {
      document
          .getElementsByTagName("body")[0]
          .classList.remove("navbar-nav-show");
    },
    async doAfterLogin() {
      console.log("Responding to `afterlogin` event with `doAfterLogin`");
      let toRoute = "New Inquiry";
      console.log(this.$store.getters.clients);
      if (this.$store.getters.clients.length === 1) {
        // Only a single client available for this user, select it and go straight to the programmes overview.
        this.$store.commit(SET_SINGLE_CLIENT);
        //toRoute = "Programmes";
      }
      if (this.$store.getters.passwordExpired) {
        toRoute = "Password Expired";
      }
      console.log('Route:' + toRoute);
      this.$router.push({ name: toRoute });
    },
  },
};
</script>
<style>

.new-auth-layout {
  height: 100vh;
}
.navbar-header {
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  border-bottom: none;
}

@media (min-width: 992px) {
  .navbar-header .navbar-brand {
    width: 160px;
  }
}
.content-row {
  height: calc(100vh - 50px);
}
.login-wrapper {
  background-color: rgb(247,250,253);
}
h1 {
  line-height: 3;
}
@media (max-width: 991px) {
  .content-padding {
    height: calc(100vh);
  }
}
@media (min-width: 992px) {
  .content-padding {
    padding-top: 0px;
  }
}

@media (max-height: 610px) {
  .hidden-logo {
    display: none;
  }
}

.container-fluid {
  width: calc(100% - 20px);
  padding-left: 0;
  padding-right: 0;
}

.login-row {
  margin-top: 20vh;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.client-logo-centered {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}
.row-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .row-2 .client-logo-centered {
    max-width: 30%;
    margin: 30px 1%;
  }
}
@media (min-width: 992px) {
  .row-2 .client-logo-centered {
    margin: 30px 15px;
  }
}



</style>
