export const formatThousands = function (value, sigFig , decimals = 2) {
    let pattern = /^-?[0-9.,]+$/g;
    if (isNaN(parseFloat(value)) || !pattern.test(value)) {
        return "";
    }
    // Remove commas
    value = parseFloat(value.toString().replace(/,/g, ''));

    let options = { maximumFractionDigits: decimals };

    if (!Number.isInteger(parseFloat(value.toFixed(2)))) {
        options.minimumFractionDigits = 2;
    }

    if (sigFig !== undefined) {
        options['maximumSignificantDigits'] = sigFig;
    }
    return new Intl.NumberFormat(
        'en-GB',
        options
    ).format(parseFloat(value));
}
