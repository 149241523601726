import axios from 'axios'
import router from "@/router";
import store from "@/store";
import Vue from 'vue';
import * as Sentry from '@sentry/vue';

const ApiService = {
    init(apiBaseUrl) {
        axios.defaults.baseURL = apiBaseUrl;
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.interceptors.response.use(
            response => response,
            this.errorResponseInterceptor
        );
        return axios.get('csrf-cookie')
            .catch((err) => {
                console.error(err, " when requesting CSRF cookie")
                Sentry.captureException(err);
            });
    },

    get(resource, config = null) {
        return axios.get(resource, config);
    },

    post(resource, data) {
        return axios.post(resource, data);
    },

    delete(resource) {
        // TODO: I'm not sure if delete is supported out of the box by laravel...
        //  May need to use post with an extra field to contain request method...
        return axios.delete(resource);
    },

    // This method is required where we need more control over a request
    //
    // Data should contain; method, url, data (payload), (optional) auth info
    customRequest(data) {
        return axios(data);
    },

    errorResponseInterceptor(error) {
        const vm = new Vue()

        if (error.response === undefined) {
            vm.$bvToast.toast(
                "Please connect to the internet to continue using iCede Portal",
                {title: "Internet Connection", variant: 'warning', noAutoHide: true}
            );
            return Promise.reject(error);
        }
        switch (error.response.status) {
            case 401:
            case 419:
                if (router.currentRoute.name !== 'Login') {
                    vm.$bvToast.toast(
                        'Your user session has timed out, please login and try again',
                        {title: 'Session Timeout', variant: 'warning'}
                    );
                    store.dispatch('logout');
                    router.push({name: 'Login'});
                }
                break;
            case 500:
            case 502:
                console.error(error);
                vm.$bvToast.toast(
                    'An unexpected error occurred during your request, please try again later',
                    {title: 'Unexpected Error', variant: 'danger'}
                );
                break;
            default:
                return Promise.reject(error);
        }
    }
}

export default ApiService;
